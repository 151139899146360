.careers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between; 
}

.career__column {
  flex: 0 0 calc(33.333% - 10px); 
  box-sizing: border-box;
}