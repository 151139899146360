.OurPartner {
     background-color: rgb(219, 218, 218);
    font-size: 20px;
    padding: 10px;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    transition: border-color 0.3s ease-in-out;
}

.OurPartner:hover {
    background-color: rgb(155, 190, 243); 
}