.logo-header {
  height: 70px;
  }

/* Styles for small screens (e.g., mobile devices) */
@media (max-width: 767px) {
  .logo-header {
    height: 60px; /* Adjust the height for smaller screens if needed */
    padding: 10px 20px; /* Add some padding for better spacing */
  }

  .md:flex {
    display: none; /* Hide the navigation links for small screens */
  }

  .md:hidden {
    display: block; /* Display the burger menu for small screens */
  }
}

/* Styles for medium to large screens */
@media (min-width: 768px) {
  .md:hidden {
    display: none; /* Hide the burger menu for medium to large screens */
  }
}

.resources{
  z-index: 2000;
}