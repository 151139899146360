.successbtn {
    border: 3px solid black;
    font-size: 3rem;
    padding: 10px;
    width: 90px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper{
    width: 1240px;
    margin: auto;
}

@media screen and (max-width: 768px) {
  .mySwiper {
   width: 95%;
   margin-right: 40px;
  }
}

@media screen and (max-width: 820px) {
  .successbtn {

    height: 40px;
    border-radius: 10px;
   margin: 0 auto; 
    display: block; 
    font-size: 1rem;


  }
}

@media screen and (max-width: 393px) {
  .mySwiper {
   width: 80%;
   margin-right: 40px;
  }
}