.swiper-slide {
  background-size: cover;
  background-position: center;
  width: 70%;
  @media (max-width:786px) {
    width: 300px;
  }
}
.success {
   border: 3px solid black;
    font-size: 3rem;
    padding: 10px;
    width: 90px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}


