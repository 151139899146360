.banner {
  background-image: url("../../assets/background/50.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-transform: uppercase;
  z-index: -1;
}

.bannerstudent {
  background-image: url("../../assets/background/For\ Students\ page.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-transform: uppercase;
}

.bannerinstitute {
  background-image: url("../../assets/background/345.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-transform: uppercase;
}

.page_font{
   font-family: 'Merriweather' 
}