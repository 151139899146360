 body {
  margin: 0;
  padding: 0;
  font-family: 'Merriweather' 
}
@tailwind base;
@tailwind components;
@tailwind utilities;




