.container {
  max-width: 100%;
  padding: 0 20px; 
}

.grid {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 50px; 
}


@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}


.grid > div {
  text-align: center;
}


.profile-image {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}


.grid p {
  margin: 15px 0;
  max-width: 100%;
}

.chooseus{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px; 
}



/* @media (min-width: ...) { ... } */