


@media screen and (max-width: 800px) {
  .left, .main, .right {
    width: 100%; 

  }

}

@media screen and (min-width: 768px) {
  .grid1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .button{
    align-items: center;

  }
}